import styled from 'styled-components'
import { Wave } from '../../../components/wave/Wave'
import { theme } from '../../../theme/theme'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { Title2, BodyLarge } from '../../../components/styles/Typography'
import { useGetUserHealthSummaryQuery } from '../../../api/ester-b2b-api/private/users/userQueries'
import { MarkdownToReact } from '../../../components/markdown/MardownToReact'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { useSelectFirstName } from '../../../state/selectors'
import { useWindowWidth } from '../../../hooks/useWindowWidth'
import { isMobile } from '../../../utils/devices'

export const Analysis = () => {
  const { data, isLoading, isError } = useGetUserHealthSummaryQuery()
  const firstName = useSelectFirstName()
  const width = useWindowWidth()
  const isMobileDevice = isMobile(width)

  return (
    <>
      {!isMobileDevice && (
        <StyledWaveWrapper>
          <Wave color={theme.color.plum} />
        </StyledWaveWrapper>
      )}

      <ComponentLayout backgroundColor={theme.color.plum} paddingTop={isMobileDevice ? theme.spacing.xxlarge : 0}>
        <SummaryWrapper>
          {isLoading ? (
            <LoadingSpinner color={theme.color.white} size={60} />
          ) : (
            <Container>
              <StyledTitle2>Hej, {firstName},</StyledTitle2>
              {isError || !data ? (
                <BodyLarge>Något gick fel, försök igen eller kontakta support på support@estercare.com</BodyLarge>
              ) : (
                data && <MarkdownToReact markdown={data} />
              )}
            </Container>
          )}
        </SummaryWrapper>
      </ComponentLayout>
    </>
  )
}

const SummaryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 355px);
`
const StyledWaveWrapper = styled.div`
  z-index: 0;
  margin-top: -${theme.spacing.xxlarge}px;
`

const Container = styled.div`
  background-color: ${theme.color.white};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-width: 748px;
  padding: ${theme.spacing.large}px ${theme.spacing.xxlarge}px;
  width: 100%;
`

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`
