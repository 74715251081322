import { FC, useState } from 'react'
import { theme } from '../theme/theme'
import styled from 'styled-components'
import { Title4 } from './styles/Typography'

export type TabData = {
  label: string
  content: React.ReactNode
}

export const Tabs: FC<{ tabs: TabData[] }> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0)

  return (
    <>
      <TabGroup>
        {tabs.map((tab, index) => (
          <TabContainer key={index}>
            <StyledTab key={`tab-${index}`} onClick={() => setActiveTab(index)}>
              <Title4>{tab.label}</Title4>
            </StyledTab>
            <UnderLine key={`underline-${index}`} className={index === activeTab ? 'active' : ''} />
          </TabContainer>
        ))}
      </TabGroup>
      {tabs.map((tab, index) => index === activeTab && <div key={index}>{tab.content}</div>)}
    </>
  )
}

const TabGroup = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 999;
  width: 468px;
  margin: 0 ${theme.spacing.jumbo}px;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    width: 90%;
    margin: 0 auto;
    padding: ${theme.spacing.mediumLarge}px 5%;
  }
`

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: -2px;
`

const StyledTab = styled.button`
  cursor: pointer;
  width: 100%;
  height: 61px;
  border: none;
  background-color: transparent;
  font-family: ${theme.font.body};
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    height: 50px;
  }
`

const UnderLine = styled.span`
  width: 100%;
  height: 5px;
  background-color: ${theme.color.grey3};
  border-radius: 50px;
  &.active {
    background-color: ${theme.color.plum};
    z-index: 1;
  }
`
