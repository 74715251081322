import { HeartPalpitation, Home, Paper, QuestionMark, Search, StethoscopePlus } from '../../components/icons'
import { env } from '../environments/environmentVariables'
import { MenuItem } from './types'

export const employeeMenu: MenuItem[] = [
  { name: 'Hem', path: '/portal', icon: Home },
  { name: 'Hälsotjänster', path: '/portal/halsotjanster', icon: StethoscopePlus },
  { name: 'Utbildning', path: '/portal/utbildning', icon: Paper },
  ...(!env.isProd('VITE_NODE_ENV') ? [{ name: 'Din hälsa', path: '/portal/din-halsa', icon: HeartPalpitation }] : []),
  { name: 'Kunskapsbank', path: '/portal/kunskapsbank', icon: Search },
  { name: 'Support', path: '/portal/support', icon: QuestionMark },
]
