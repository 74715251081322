import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { Header } from '../../../components/Header'
import { theme } from '../../../theme/theme'
import { BodyLarge } from '../../../components/styles/Typography'
import { Analysis } from './Analysis'
import { HealthPlan } from './HealthPlan'
import { TabData, Tabs } from '../../../components/Tabs'
import { useGetScreeningResultStatus } from '../../../api/ester-b2b-api/private/screeningResult/screeningResultQueries'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { isMobile } from '../../../utils/devices'
import { useWindowWidth } from '../../../hooks/useWindowWidth'

const tabsData: TabData[] = [
  { label: 'Din analys', content: <Analysis /> },
  { label: 'Din hälsoplan', content: <HealthPlan /> },
]

export const YourHealthIndex = () => {
  const { data, isLoading: isStatusLoading } = useGetScreeningResultStatus()

  const width = useWindowWidth()
  const isMobileDevice = isMobile(width)

  if (isStatusLoading) return <LoadingSpinner color={theme.color.plum} size={60} />

  return (
    <>
      <Header
        backgroundColor={theme.color.transparent}
        title="Din hälsa"
        maxWidth={740}
        noPaddingBottom={isMobileDevice}
      />
      {data?.status === 'HANDLED' ? (
        <Tabs tabs={tabsData} />
      ) : (
        <ComponentLayout>
          <BodyLarge>
            När du är klar med screeningen kommer din personliga hälsoplan visas här. (Copy och design kommer)
          </BodyLarge>
        </ComponentLayout>
      )}
    </>
  )
}
