import styled from 'styled-components'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { Title2 } from '../../../components/styles/Typography'
import { Wave } from '../../../components/wave/Wave'
import { theme } from '../../../theme/theme'
import { isMobile } from '../../../utils/devices'
import { useWindowWidth } from '../../../hooks/useWindowWidth'

export const HealthPlan = () => {
  const width = useWindowWidth()
  const isMobileDevice = isMobile(width)
  return (
    <>
      {!isMobileDevice && (
        <StyledWaveWrapper>
          <Wave color={theme.color.plum} />
        </StyledWaveWrapper>
      )}
      <ComponentLayout backgroundColor={theme.color.plum} paddingTop={isMobileDevice ? theme.spacing.xxlarge : 0}>
        <Container>
          <StyledTitle2>Din hälsoplan</StyledTitle2>
        </Container>
      </ComponentLayout>
    </>
  )
}

const Container = styled.div`
  min-height: 80vh;
`

const StyledWaveWrapper = styled.div`
  z-index: 0;
  margin-top: -${theme.spacing.xxlarge}px;
`

const StyledTitle2 = styled(Title2)`
  color: ${theme.color.white};
`
