import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Label } from './styles/Typography'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export type SelectOption = {
  value: string
  label: string
}

type ThemeColorKeys = keyof typeof theme.color

type SelectInputProps = {
  label?: string
  options: SelectOption[]
  value: string
  onChange: (value: string) => void
  placeholder?: string
  backgroundColor?: ThemeColorKeys
}

export const SelectInput = ({ label, options, value, onChange, placeholder, backgroundColor }: SelectInputProps) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value as string)
  }

  return (
    <StyledFormControl>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSelect
        displayEmpty
        label="label"
        value={value}
        onChange={handleChange}
        $backgroundColor={backgroundColor}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {placeholder && (
          <StyledMenuItem disabled value="">
            {placeholder}
          </StyledMenuItem>
        )}
        {options.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  )
}

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

const StyledLabel = styled(Label)`
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: 12.8px;
  text-transform: none;
`

const StyledSelect = styled(Select)<{ $backgroundColor?: ThemeColorKeys }>`
  && {
    background-color: ${({ $backgroundColor }) =>
      $backgroundColor ? theme.color[$backgroundColor] : theme.color.white};

    &.Mui-focused {
      border: 1px solid ${theme.color.plum};
    }

    &:hover {
      border-color: transparent;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`

const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: ${theme.fontSize.xsmall}px;
  }
`
