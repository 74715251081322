import { appConfig } from '../../../../config'

export const getScreeningResultStatus = async ({ accessToken }: { accessToken: string }) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/users/screeningResultStatus`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return res.json()
}
