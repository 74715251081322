import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const StethoscopePlus = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.924 16.5393C20.5809 16.5393 21.924 15.1961 21.924 13.5393C21.924 11.8824 20.5809 10.5393 18.924 10.5393C17.2672 10.5393 15.924 11.8824 15.924 13.5393C15.924 15.1961 17.2672 16.5393 18.924 16.5393Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.03797 1.95392C4.23225 1.95392 3.45953 2.25543 2.8898 2.79212C2.32007 3.32881 2 4.05671 2 4.81571V8.63142C2 9.89641 2.53345 11.1096 3.483 12.0041C4.43255 12.8986 5.72042 13.4011 7.06329 13.4011C8.40616 13.4011 9.69403 12.8986 10.6436 12.0041C11.5931 11.1096 12.1266 9.89641 12.1266 8.63142V4.81571C12.1266 4.05671 11.8065 3.32881 11.2368 2.79212C10.667 2.25543 9.89433 1.95392 9.08861 1.95392"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.06328 13.4011L7.06328 17.4553C7.06328 24.8482 18.962 24.8482 18.962 17.4553V16.5014"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M5.03801 1V3.38482" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.08859 1V3.38482" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9C20.2091 9 22 7.20914 22 5C22 2.79086 20.2091 1 18 1C15.7909 1 14 2.79086 14 5C14 7.20914 15.7909 9 18 9ZM18 2.5C18.2761 2.5 18.5 2.72386 18.5 3V4.5H20C20.2761 4.5 20.5 4.72386 20.5 5C20.5 5.27614 20.2761 5.5 20 5.5H18.5V7C18.5 7.27614 18.2761 7.5 18 7.5C17.7239 7.5 17.5 7.27614 17.5 7V5.5H16C15.7239 5.5 15.5 5.27614 15.5 5C15.5 4.72386 15.7239 4.5 16 4.5H17.5V3C17.5 2.72386 17.7239 2.5 18 2.5Z"
      fill={color}
    />
  </svg>
)
